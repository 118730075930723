import Heading from '@pretto/bricks/components/typography/Heading'
import { Column } from '@pretto/bricks/website/rates/components/RatesGrid/styles'
import ContentBlock, { defaultOverrides } from '@pretto/bricks/website/utility/ContentBlock'

import PropTypes from 'prop-types'

import * as S from './styles'

const RatesRowRegionsText = ({ left, overrides, right, title }) => (
  <>
    <Column>
      <S.Title>
        <Heading type="h2">{title}</Heading>
      </S.Title>
    </Column>

    <Column />

    <Column>
      <ContentBlock overrides={overrides}>{left}</ContentBlock>
    </Column>

    <Column>
      <ContentBlock overrides={overrides}>{right}</ContentBlock>
    </Column>
  </>
)

RatesRowRegionsText.defaultProps = {
  overrides: defaultOverrides,
}

RatesRowRegionsText.propTypes = {
  /** Markdown content to populate the left text block. */
  left: PropTypes.string.isRequired,
  /** ContentBlock overrides. */
  overrides: PropTypes.object.isRequired,
  /** Markdown content to populate the right text block. */
  right: PropTypes.string.isRequired,
  /** Row title. */
  title: PropTypes.string.isRequired,
}

export default RatesRowRegionsText
