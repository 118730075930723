import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import { Column } from '@pretto/bricks/website/rates/components/RatesGrid/styles'

import styled from 'styled-components'

export const RatesPage = styled.div`
  padding-bottom: ${g(8)};
`

export const Content = styled.div`
  ${column([2, 4])};
  margin-top: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    margin-top: ${g(10)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};
  }
`

export const FAQ = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral4};
  margin-top: ${g(4)};
  padding: ${g(4)} 0 ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(13)};
    padding: ${g(5)} 0 ${g(10)};
  }
`

export const FAQColumn = styled(Column)`
  & + & {
    margin-top: ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    & + & {
      margin-top: 0;
    }

    &:nth-child(n + 3) {
      margin-top: ${g(4)};
    }
  }
`

export const FAQTitle = styled.div`
  margin-bottom: ${g(4)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(7)};
  }
`

export const Graph = styled.div`
  margin: ${g(4)} 0;
`

export const Introduction = styled.div`
  margin: ${g(3)} ${g(2)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: ${g(4)} ${g(5)};
  }
`

export const IntroductionExcerpt = styled.p`
  margin-bottom: ${g(2)};
`

export const IntroductionReadMoreIcon = styled.span`
  margin-left: ${g(1)};
  display: inline-block;
  position: relative;
  top: ${g(0.25)};
`

export const MoreArticlesContainer = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  padding-bottom: ${g(2)};
  padding-top: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: ${g(6)};
    padding-top: ${g(6)};
  }
`

export const Page = styled.div`
  ${grid()};
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(6)};
  }
`

export const RatesCompare = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  margin: ${g(4)} 0;
  padding: ${g(4)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: ${g(10)} 0;
  }
`

export const RelatedArticleTitle = styled.div`
  margin-bottom: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(5)};
  }
`

export const Section = styled.div`
  margin-top: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(10)};
  }
`

export const SectionHeader = styled.header`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  margin: ${g(4)} 0;
  padding: ${g(4)} ${g(2)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: ${g(10)} 0;
    padding: ${g(10)} 0;
  }
`

export const Signature = styled.div`
  align-items: center;
  display: flex;
  margin: ${g(3)} 0;
`

export const Title = styled.div`
  display: flex;
  margin-bottom: ${g(3)};
`

export const TitleEmoji = styled.span`
  margin-right: ${g(1)};
`

export const TitleSmall = styled(Title)`
  margin-bottom: ${g(2)};
`
