import { breakpoints, g } from '@pretto/bricks/components/layout'

import { RatesSection } from '@pretto/website/src/templates/blocs/rows/rowRates/components/RatesSection'
import { Rates } from '@pretto/website/src/templates/blocs/rows/rowRates/types/rates'

import styled, { ThemeProvider } from 'styled-components'

type RatePageHeroProps = {
  date: string
  hero: Rates
  title: string
}

export const RatePageHero: React.FC<RatePageHeroProps> = ({ date, hero, title, ...props }) => (
  <ThemeProvider theme={{ isSingleValue: hero.rates.length === 1 }}>
    <RatePageHeroContainer {...props}>
      <Title>{title}</Title>

      <SubTitle>
        Taux du marché observés par Pretto, mis à jour le <Date>{date}</Date>.
      </SubTitle>

      <RatesSection {...hero} />
    </RatePageHeroContainer>
  </ThemeProvider>
)

const RatePageHeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral5};
  border-radius: ${g(1)};
  padding-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${({ theme: { isSingleValue } }) =>
      isSingleValue ? `${g(4)} ${g(10)} ${g(8)} ${g(10)}` : `${g(4)} ${g(2)} ${g(7)} ${g(2)}`};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${({ theme: { isSingleValue } }) =>
      isSingleValue ? `${g(4)} ${g(13)} ${g(8)} ${g(13)}` : `${g(4)} ${g(5)} ${g(8)} ${g(5)}`};
  }
`

const Title = styled.h1`
  ${({ theme }) => theme.typos.heading4};
  text-align: center;
  margin-bottom: ${g(2)};
  padding: 0px ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading3};
    margin-bottom: ${g(3)};
    padding: 0px;
  }
`

const SubTitle = styled.div`
  ${({ theme }) => theme.typos.body4};
  text-align: center;
  margin-bottom: ${g(3)};
  padding: 0px ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(5)};
    padding: 0px;
  }
`

const Date = styled.span`
  ${({ theme }) => theme.typos.body4Bold};
`
