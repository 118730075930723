import { g } from '@pretto/bricks/components/layout'
import Image from '@pretto/bricks/website/shared/components/Image'
import styled from 'styled-components'

export const GraphBlock = styled.div`
  padding-top: 56%;
`

export const ListBullet = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.legacy.colors.primary1.light};
  border-radius: ${g(2)};
  color: ${({ theme }) => theme.legacy.colors.primary1.default};
  display: flex;
  flex-shrink: 0;
  font-size: ${g(3)};
  font-weight: 700;
  height: ${g(4)};
  justify-content: center;
  margin-right: ${g(3)};
  margin-top: ${g(1)};
  width: ${g(4)};
`

export const ListItem = styled.li`
  display: flex;

  & + & {
    margin-top: ${g(3)};
  }
`

export const ListImageContainer = styled.div`
  max-width: ${g(36)};
  margin: 0 auto ${g(3)};
  padding-top: 60%;
  position: relative;
`

export const ListImage = styled(Image)`
  height: 100%;
  left: 0;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
`

export const ListTitle = styled.div`
  margin-bottom: ${g(3)};
  text-align: center;
`

export const Title = styled.div`
  display: flex;
  margin-bottom: ${g(3)};
`
