import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import RatesCompare from '@pretto/bricks/website/rates/components/RatesCompare'
import RatesGraphLegend from '@pretto/bricks/website/rates/components/RatesGraphLegend'
import { Column, Row as RowComponent, RowReverse } from '@pretto/bricks/website/rates/components/RatesGrid/styles'
import RatesRowDistributionText from '@pretto/bricks/website/rates/components/RatesRowDistributionText'
import RatesRowListText from '@pretto/bricks/website/rates/components/RatesRowListText'
import RatesRowRegionsText from '@pretto/bricks/website/rates/components/RatesRowRegionsText'
import RatesRowTextText from '@pretto/bricks/website/rates/components/RatesRowTextText'
import RatesRowWagesText from '@pretto/bricks/website/rates/components/RatesRowWagesText'
import AuthorSignature from '@pretto/bricks/website/shared/components/AuthorSignature'
import { components, defaultOverrides } from '@pretto/bricks/website/utility/ContentBlock'
import Link from '@pretto/bricks/website/utility/Link'

import { RatePageHero } from '@pretto/website/src/templates/tauxImmobilier/components/RatePageHero'

import PropTypes from 'prop-types'

import * as S from './styles'

const renderRow = (Controller, graphs, defaultDuration, { __typename, ...props }, index) => {
  const Row = index % 2 === 0 ? RowComponent : RowReverse

  const rowProps = {
    overrides: { ...defaultOverrides, Cta: { component: components.Cta, props: { align: 'left' } } },
    ...props,
  }

  return (
    <Row key={index}>
      <Controller defaultDuration={defaultDuration}>
        {props => {
          switch (__typename) {
            case 'WpTemplate_TauxImmobilier_Rates_RatesContentRows_RatesContentRowsDistributionText':
            case 'WpTemplate_TauxImmobilierDure_Ratesduration_RatesContentRows_RatesContentRowsDistributionText':
            case 'WpTemplate_TauxImmobilierRgion_Ratesregion_RatesContentRows_RatesContentRowsDistributionText':
            case 'WpTemplate_TauxImmobilierDureRgion_Ratesdurationregion_RatesContentRows_RatesContentRowsDistributionText':
              return <RatesRowDistributionText {...rowProps} {...props(graphs.distribution)} />

            case 'WpTemplate_TauxImmobilier_Rates_RatesContentRows_RatesContentRowsListText':
            case 'WpTemplate_TauxImmobilierDure_Ratesduration_RatesContentRows_RatesContentRowsListText':
            case 'WpTemplate_TauxImmobilierRgion_Ratesregion_RatesContentRows_RatesContentRowsListText':
            case 'WpTemplate_TauxImmobilierDureRgion_Ratesdurationregion_RatesContentRows_RatesContentRowsListText':
              return <RatesRowListText {...rowProps} />

            case 'WpTemplate_TauxImmobilier_Rates_RatesContentRows_RatesContentRowsRegionsText':
            case 'WpTemplate_TauxImmobilierDure_Ratesduration_RatesContentRows_RatesContentRowsRegionsText':
            case 'WpTemplate_TauxImmobilierRgion_Ratesregion_RatesContentRows_RatesContentRowsRegionsText':
            case 'WpTemplate_TauxImmobilierDureRgion_Ratesdurationregion_RatesContentRows_RatesContentRowsRegionsText':
              return <RatesRowRegionsText {...rowProps} {...props(graphs.regions)} />

            case 'WpTemplate_TauxImmobilier_Rates_RatesContentRows_RatesContentRowsTextText':
            case 'WpTemplate_TauxImmobilierDure_Ratesduration_RatesContentRows_RatesContentRowsTextText':
            case 'WpTemplate_TauxImmobilierRgion_Ratesregion_RatesContentRows_RatesContentRowsTextText':
            case 'WpTemplate_TauxImmobilierDureRgion_Ratesdurationregion_RatesContentRows_RatesContentRowsTextText':
              return <RatesRowTextText {...rowProps} />

            case 'WpTemplate_TauxImmobilier_Rates_RatesContentRows_RatesContentRowsWagesText':
            case 'WpTemplate_TauxImmobilierDure_Ratesduration_RatesContentRows_RatesContentRowsWagesText':
            case 'WpTemplate_TauxImmobilierRgion_Ratesregion_RatesContentRows_RatesContentRowsWagesText':
            case 'WpTemplate_TauxImmobilierDureRgion_Ratesdurationregion_RatesContentRows_RatesContentRowsWagesText':
              return <RatesRowWagesText {...rowProps} {...props(graphs.wages)} />

            default:
              return null
          }
        }}
      </Controller>
    </Row>
  )
}

const RatesPage = ({
  content,
  contentTitle,
  date,
  defaultDuration,
  graphs,
  hero,
  introduction,
  ratesAuthor,
  rows,
  select: Controller,
  settings,
  summary,
  title,
}) => (
  <S.RatesPage>
    <Wrapper full="tablet">
      <RatePageHero title={title} hero={hero} date={date} />

      {introduction && <S.Introduction>{introduction}</S.Introduction>}
    </Wrapper>

    {introduction && (
      <Wrapper>
        <Divider variant="neutral-1-20" />
      </Wrapper>
    )}

    <S.Section>
      <Wrapper>
        <RowComponent>
          <Column>
            <S.Title>
              <Heading size="large">{settings.title}</Heading>
            </S.Title>

            <S.IntroductionExcerpt>« {settings.news.excerpt} »</S.IntroductionExcerpt>

            <Divider variant="neutral-1-20" />

            <S.Signature>
              <AuthorSignature {...ratesAuthor} format="small" noShare />
            </S.Signature>

            <Text variant="primary-1">
              <Link href={settings.news.uri}>
                {settings.anchor}
                <S.IntroductionReadMoreIcon>
                  <Icon name="arrow-right" variant="primary-1" />
                </S.IntroductionReadMoreIcon>
              </Link>
            </Text>
          </Column>

          <Column>
            <Card format="line" overflowVisible variant="neutral-1-20">
              <Content>
                <Controller defaultDuration={defaultDuration}>
                  {props => {
                    const { graph, legend, select: Select } = props(graphs.evolution)

                    return (
                      <>
                        <Select>{({ duration }) => <>Évolution des taux pour un prêt {duration}.</>}</Select>
                        <S.Graph>{graph}</S.Graph>
                        <RatesGraphLegend items={legend} />
                      </>
                    )
                  }}
                </Controller>
              </Content>
            </Card>
          </Column>
        </RowComponent>
      </Wrapper>
    </S.Section>

    {rows?.length > 0 && title && (
      <S.SectionHeader>
        <Heading size="large">{contentTitle}</Heading>
      </S.SectionHeader>
    )}

    {false && <Wrapper>{rows.slice(0, 1).map(renderRow.bind(null, Controller, graphs, defaultDuration))}</Wrapper>}

    {false && (
      <S.RatesCompare>
        <Wrapper>
          <RatesCompare />
        </Wrapper>
      </S.RatesCompare>
    )}

    {rows?.length > 0 && <Wrapper>{rows.map(renderRow.bind(null, Controller, graphs, defaultDuration))}</Wrapper>}

    {summary && (
      <S.FAQ>
        <Wrapper>
          <S.FAQTitle>
            <SubHeading size="large">{summary.title}</SubHeading>
          </S.FAQTitle>

          <RowComponent>
            {summary.qa.map(({ answer, emoji, question }, index) => (
              <S.FAQColumn itemScope itemProp="mainEntity" itemType="https://schema.org/Question" key={index}>
                <S.TitleSmall>
                  <S.TitleEmoji>
                    <Emoji>{`:${emoji}:`}</Emoji>
                  </S.TitleEmoji>

                  <SubHeading itemProp="name">{question}</SubHeading>
                </S.TitleSmall>
                <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                  <p itemProp="text">{answer}</p>
                </div>
              </S.FAQColumn>
            ))}
          </RowComponent>
        </Wrapper>
      </S.FAQ>
    )}

    {content && (
      <S.Page>
        <S.Content>{content}</S.Content>
      </S.Page>
    )}
  </S.RatesPage>
)

RatesPage.defaultProps = {
  rows: [],
}

RatesPage.propTypes = {
  /** Main content. */
  content: PropTypes.node,
  /** Main content title. */
  contentTitle: PropTypes.string.isRequired,
  /** Page last updated date. */
  date: PropTypes.string.isRequired,
  /** Duration to be set by default. */
  defaultDuration: PropTypes.number,
  /** Set of graphs to be consumed by content. */
  graphs: PropTypes.shape({
    distribution: PropTypes.shape({
      component: PropTypes.elementType.isRequired,
      props: PropTypes.func.isRequired,
    }).isRequired,
    evolution: PropTypes.shape({
      component: PropTypes.elementType.isRequired,
      props: PropTypes.func.isRequired,
    }).isRequired,
    regions: PropTypes.shape({
      component: PropTypes.elementType.isRequired,
      props: PropTypes.func.isRequired,
    }).isRequired,
    wages: PropTypes.shape({
      component: PropTypes.elementType.isRequired,
      props: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  /** Hero labels. */
  hero: PropTypes.shape({
    advertisement: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
    form: PropTypes.array.isRequired,
    help: PropTypes.string,
    rates: PropTypes.array.isRequired,
    tooltip: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  /** Page introduction paragraph. */
  introduction: PropTypes.string,
  /** Articles at the end of the page that are related to its content. */
  linkedArticles: PropTypes.array,
  /** Articles at the end of the page that are related to its content. */
  linkedSubArticles: PropTypes.array,
  /** Rates author object to be passed down to the signature. */
  ratesAuthor: PropTypes.object.isRequired,
  /** List of rows. Properties depends on __typename. */
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      __typename: PropTypes.string.isRequired,
    })
  ),
  /** Select component. */
  select: PropTypes.elementType.isRequired,
  /** Rates settings to be used for the introduction. */
  settings: PropTypes.shape({
    anchor: PropTypes.string.isRequired,
    news: PropTypes.shape({
      date: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  /** Summary and Q&As. */
  summary: PropTypes.shape({
    qa: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.string.isRequired,
        emoji: PropTypes.string.isRequired,
        question: PropTypes.string.isRequired,
      })
    ),
    title: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
}

export default RatesPage
