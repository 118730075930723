import RatesGraphCumulativeDistribution from '@pretto/bricks/website/rates/components/RatesGraphCumulativeDistribution'
import RatesGraphEvolution from '@pretto/bricks/website/rates/components/RatesGraphEvolution'
import RatesGraphWages from '@pretto/bricks/website/rates/components/RatesGraphWages'
import RatesMap from '@pretto/bricks/website/rates/components/RatesMap'
import RatesPage from '@pretto/bricks/website/rates/pages/RatesPage'

import { ContentPage } from '@pretto/website/src/components/ContentPage/ContentPage'
import RatesSelectController from '@pretto/website/src/components/Rates/RatesSelectController'
import { LANDING_PAGE_SEO } from '@pretto/website/src/config/pagesUrls'
import { applyRegion } from '@pretto/website/src/templates/tauxImmobilier/lib/applyRegion'
import { ratesDistribution } from '@pretto/website/src/templates/tauxImmobilier/lib/ratesDistribution'
import { ratesEvolution } from '@pretto/website/src/templates/tauxImmobilier/lib/ratesEvolution'
import { ratesProfile } from '@pretto/website/src/templates/tauxImmobilier/lib/ratesProfile'
import { ratesProfileRegion } from '@pretto/website/src/templates/tauxImmobilier/lib/ratesProfileRegion'
import { ratesRegion } from '@pretto/website/src/templates/tauxImmobilier/lib/ratesRegion'
import { updatedDate } from '@pretto/website/src/templates/tauxImmobilier/lib/updatedDate'
import { useRatesForm } from '@pretto/website/src/templates/tauxImmobilier/lib/useRatesForm'

import PropTypes from 'prop-types'

export const TauxImmobilier = ({
  data: {
    rates: { data: rates },
    wp: {
      acfOptionsRatesSettings: { ratesSettings },
    },
    wpPost: data,
  },
}) => {
  const {
    author: { node: author },
    date: publishedDate,
    modified,
    title,
  } = data

  const templateQuery =
    data.template.rates ??
    data.template.ratesBarometer ??
    data.template.ratesDuration ??
    data.template.ratesDurationRegion ??
    data.template.ratesRegion

  const {
    duration,
    introduction,
    ratesContentRows,
    ratesContentTitle,
    ratesHeroHelp,
    ratesHeroSimulationCta,
    ratesHeroSimulationUrl,
    ratesSummaryQa,
    ratesSummaryTitle,
    region,
  } = templateQuery

  const { values, form } = useRatesForm({
    preSelectedValues: { region },
    rates: rates.marketRatesProfileLatest,
  })

  const defaultDuration = duration ? parseInt(duration, 10) : undefined

  const graphs = {
    distribution: {
      component: RatesGraphCumulativeDistribution,
      props: () =>
        ratesDistribution(
          applyRegion({ rates: rates.marketRatesLatest, ratesRegion: rates.marketRatesRegionLatest, region })
        ),
    },
    evolution: {
      component: RatesGraphEvolution,
      props: () => ratesEvolution(rates.marketRatesRegionHistory, region),
    },
    regions: {
      component: RatesMap,
      props: (...props) => ratesRegion(rates.marketRatesRegionLatest, ...props),
    },
    wages: {
      component: RatesGraphWages,
      props: (...props) => ratesProfile(rates.marketRatesProfileRegionLatest, region, ...props),
    },
  }

  const summary = ratesSummaryQa && ratesSummaryTitle ? { qa: ratesSummaryQa, title: ratesSummaryTitle } : null

  const heroRates = ratesProfileRegion(rates.marketRatesProfileRegionLatest, {
    ...values,
    defaultDuration,
  })

  const props = {
    contentTitle: ratesContentTitle,
    date: updatedDate({
      displayTodayAsPublicationDate: true,
      modified,
      publishedDate,
    }).format('LL'),
    defaultDuration,
    graphs,
    hero: {
      cta: ratesHeroSimulationCta,
      form,
      help: ratesHeroHelp,
      isSingleValue: heroRates.length === 1,
      rates: heroRates,
      url: ratesHeroSimulationUrl ?? LANDING_PAGE_SEO,
    },
    introduction,
    ratesAuthor: author,
    rows: ratesContentRows,
    select: RatesSelectController,
    settings: ratesSettings,
    summary,
    title,
    ...(data.content
      ? {
          content: <ContentPage data={data} />,
        }
      : {}),
  }

  if (summary) {
    return (
      <div itemScope itemType="https://schema.org/FAQPage">
        <RatesPage {...props} />
      </div>
    )
  }
  return <RatesPage {...props} />
}

TauxImmobilier.propTypes = {
  data: PropTypes.object.isRequired,
}
