import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Graph = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40;
`

export const ProfileRange = styled.g`
  transition: all 600ms cubic-bezier(0.16, 1, 0.3, 1);
`

export const Range = styled.div`
  flex: 1;
`

export const Rect = styled.rect`
  cursor: help;
  transition: all 600ms cubic-bezier(0.16, 1, 0.3, 1);
`

export const Salary = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: ${g(2)};
  font-weight: 700;
  height: ${g(11)};
  padding-right: ${g(4)};
  padding-top: ${g(6)};
`
