import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const GraphBlock = styled.div`
  padding-top: 56%;
`

export const Title = styled.div`
  display: flex;
  margin-bottom: ${g(3)};
`
