import { mapValueInRange } from '@pretto/bricks/core/utility/math'
import { useTooltip } from '@pretto/bricks/core/utility/tooltip'
import AnimatedIncrementingNumber from '@pretto/bricks/shared/components/AnimatedIncrementingNumber'

import PropTypes from 'prop-types'
import { forwardRef, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import * as S from './styles'

const STAGGERING_DELAY = 50
const UNIT_FADING = 60
const UNIT_TRANSLATE_Y = 32

const RatesGraphWages = forwardRef(({ limitMax, limitMin, mortgagors, rates, isVisible }, ref) => {
  const [setRefController, tooltipComponent] = useTooltip()
  const {
    legacy: { colors },
  } = useContext(ThemeContext)

  return (
    <S.Graph ref={ref}>
      <div>
        {rates.map(({ salary }, index) => (
          <S.Salary key={index}>{salary}</S.Salary>
        ))}
      </div>

      <S.Range>
        {rates.map(({ ranges, rateMax, rateMin, salary }, index) => {
          const xMax = mapValueInRange(rateMax, limitMin, limitMax, 0, 100)
          const xMin = mapValueInRange(rateMin, limitMin, limitMax, 0, 100)

          const y = isVisible ? 0 : UNIT_TRANSLATE_Y

          return (
            <div key={index}>
              <svg height="88" width="100%">
                <S.ProfileRange
                  style={{
                    transform: `translate(${xMin}%, ${y}px)`,
                    transitionDelay: `${index * STAGGERING_DELAY}ms`,
                  }}
                >
                  <line stroke={colors.neutral1.fade(UNIT_FADING)} strokeDasharray="4" x1="0" x2="0" y1="38" y2="68" />
                  <text fill={colors.neutral1.fade(UNIT_FADING)} fontSize="14" textAnchor="middle" x="0" y="32">
                    <AnimatedIncrementingNumber suffix="%" value={rateMin} />
                  </text>
                </S.ProfileRange>

                <S.ProfileRange
                  style={{
                    transform: `translate(${xMax}%, ${y}px)`,
                    transitionDelay: `${index * STAGGERING_DELAY}ms`,
                  }}
                >
                  <line stroke={colors.neutral1.fade(UNIT_FADING)} strokeDasharray="4" x1="0" x2="0" y1="38" y2="68" />
                  <text fill={colors.neutral1.fade(UNIT_FADING)} fontSize="14" textAnchor="middle" x="0" y="32">
                    <AnimatedIncrementingNumber suffix="%" value={rateMax} />
                  </text>
                </S.ProfileRange>

                <rect fill={colors.neutral1.light} height="40" rx="4" ry="4" width="100%" x="0" y="48" />

                {ranges.map(({ label, rate, relativeWidth, relativeX, variant }, rangeIndex) => {
                  const limitDelta = Math.max(0, limitMax - limitMin)

                  const width = mapValueInRange(relativeWidth, 0, limitDelta, 0, 100)
                  const x = mapValueInRange(relativeX, limitMin, limitMax, 0, 100)

                  return (
                    <S.Rect
                      fill={colors[variant]}
                      height="40"
                      key={rangeIndex}
                      ref={setRefController.bind(
                        null,
                        <>
                          {label.single ?? label} : <strong>{rate} %</strong> pour {mortgagors} emprunteur
                          {mortgagors > 1 && 's'} avec {salary} de revenus net par an.
                        </>
                      )}
                      style={{
                        transitionDelay: `${index * STAGGERING_DELAY}ms`,
                      }}
                      width={isVisible ? `${width}%` : 0}
                      x={isVisible ? `${x}%` : `${xMin}%`}
                      y="48"
                    />
                  )
                })}
              </svg>
            </div>
          )
        })}
      </S.Range>

      {tooltipComponent}
    </S.Graph>
  )
})

RatesGraphWages.defaultProps = {
  isVisible: true,
}

RatesGraphWages.displayName = 'RatesGraphWages'

RatesGraphWages.propTypes = {
  /** Whether of the graph should animate in. */
  isVisible: PropTypes.bool,
  /** Maximum rate limite that defines X axis boundaries. */
  limitMax: PropTypes.number.isRequired,
  /** Minimum rate limite that defines X axis boundaries. */
  limitMin: PropTypes.number.isRequired,
  /** Mortgagors count. */
  mortgagors: PropTypes.number.isRequired,
  /** Rates definition. */
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      ranges: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.shape({ multiple: PropTypes.string.isRequired }), PropTypes.string])
            .isRequired,
          rate: PropTypes.number.isRequired,
          relativeWidth: PropTypes.number.isRequired,
          relativeX: PropTypes.number.isRequired,
          variant: PropTypes.string.isRequired,
        })
      ).isRequired,
      rateMax: PropTypes.number.isRequired,
      rateMin: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default RatesGraphWages
