import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import Heading from '@pretto/bricks/components/typography/Heading'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import { Column } from '@pretto/bricks/website/rates/components/RatesGrid/styles'
import ContentBlock, { defaultOverrides } from '@pretto/bricks/website/utility/ContentBlock'

import PropTypes from 'prop-types'

import * as S from './styles'

const RatesRowListText = ({ content, items, overrides, title }) => (
  <>
    <Column>
      <S.Title>
        <Heading type="h2">{title}</Heading>
      </S.Title>

      <ContentBlock overrides={overrides}>{content}</ContentBlock>
    </Column>

    <Column>
      <Card format="line" variant="neutral-1-20">
        <Content>
          <S.ListTitle>
            <SubHeading size="large" variant="primary-1">
              Pretto : le bon plan !
            </SubHeading>
          </S.ListTitle>

          <S.ListImageContainer>
            <S.ListImage path="v1594830412/bricks/static-assets/decoration-banks_sight.png" />
          </S.ListImageContainer>

          <ul>
            {items.map(({ description, title }, index) => (
              <S.ListItem key={index}>
                <S.ListBullet>{index + 1}</S.ListBullet>
                <div>
                  <SubHeading variant="primary-1">{title}</SubHeading>
                  <p>{description}</p>
                </div>
              </S.ListItem>
            ))}
          </ul>
        </Content>
      </Card>
    </Column>
  </>
)

RatesRowListText.defaultProps = {
  overrides: defaultOverrides,
}

RatesRowListText.propTypes = {
  /** Markdown content to populate the text block. */
  content: PropTypes.string.isRequired,
  /** List of item to populate the list. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** ContentBlock overrides. */
  overrides: PropTypes.object,
  /** Row title. */
  title: PropTypes.string.isRequired,
}

export default RatesRowListText
