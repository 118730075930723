import { filterDataByMoreCompetitiveRegion } from '@pretto/website/src/templates/tauxImmobilier/lib/filterDataByMoreCompetitiveRegion'
import { formatPercent } from '@pretto/website/src/templates/tauxImmobilier/lib/formatPercent'
import { formatSalary } from '@pretto/website/src/templates/tauxImmobilier/lib/formatSalary'
import { Legend } from '@pretto/website/src/templates/tauxImmobilier/types/legend'
import { RawExtendedRate } from '@pretto/website/src/templates/tauxImmobilier/types/rawExtendedRate'

const LIMIT_OFFSET = 0.2

type Props = {
  duration: number
  legend: Legend[]
  mortgagors: number
}

export const ratesProfile = (
  data: RawExtendedRate[],
  region: string | undefined,
  { duration, legend, mortgagors }: Props
) => {
  const isRegionSpecified = region && region !== 'all'

  const dataFiltered = (isRegionSpecified ? data.filter(rate => rate.region === region) : data)
    // filtering by mortgagors count
    .filter(rate => rate.mortgagors === mortgagors)
    // filtering by duration
    .filter(rate => rate.duration === duration)
    // sorting by salary
    .sort(({ salaryMin: salaryMinA = 0 }, { salaryMin: salaryMinB = 0 }) => (salaryMinA ?? 0) - (salaryMinB ?? 0))
  // mapping values

  const dataFiltedByRegion = isRegionSpecified
    ? dataFiltered
    : (filterDataByMoreCompetitiveRegion(dataFiltered) as RawExtendedRate[])

  const formatedData = dataFiltedByRegion.map(
    ({
      averageRate,
      maxOrdinaryRate,
      maxVeryGoodRate,
      minOrdinaryRate,
      minVeryGoodRate,
      ordinaryRate,
      salaryMax,
      salaryMin,
      veryGoodRate,
    }) => ({
      averageRate,
      maxOrdinaryRate,
      maxVeryGoodRate,
      minOrdinaryRate,
      minVeryGoodRate,
      ordinaryRate,
      salary: formatSalary(salaryMin ?? 0, salaryMax ?? 0),
      veryGoodRate,
    })
  )

  const limitMax =
    formatedData.reduce((previous, { maxOrdinaryRate }) => {
      if (maxOrdinaryRate > previous) {
        return maxOrdinaryRate
      }

      return previous
    }, 0) + LIMIT_OFFSET

  const limitMin =
    formatedData.reduce((previous, { minVeryGoodRate }) => {
      if (minVeryGoodRate < previous) {
        return minVeryGoodRate
      }

      return previous
    }, Infinity) - LIMIT_OFFSET

  const rates = formatedData.map(formtatedSetOfData => {
    const {
      maxOrdinaryRate,
      minVeryGoodRate,
      salary,
      averageRate,
      veryGoodRate,
      ordinaryRate,
      maxVeryGoodRate,
      minOrdinaryRate,
    } = formtatedSetOfData

    const rangesByRateQaulity: { [key: string]: { baseRate: number; rateMax: number; rateMin: number } } = {
      'Meilleur taux': {
        baseRate: veryGoodRate,
        rateMax: maxVeryGoodRate,
        rateMin: minVeryGoodRate,
      },
      'Bon taux': {
        baseRate: averageRate,
        rateMax: minOrdinaryRate,
        rateMin: maxVeryGoodRate,
      },
      'Taux ordinaire': {
        baseRate: ordinaryRate,
        rateMax: maxOrdinaryRate,
        rateMin: minOrdinaryRate,
      },
    }

    const ranges = legend.map(({ label, variant }) => {
      const { baseRate, rateMax, rateMin } = rangesByRateQaulity[label.single]

      const rate = formatPercent(baseRate)

      return { label, rate, relativeWidth: Math.max(0, rateMax - rateMin), relativeX: rateMin, variant }
    })

    return { ranges, rateMax: maxOrdinaryRate, rateMin: minVeryGoodRate, salary }
  })

  return {
    limitMax,
    limitMin,
    rates,
  }
}
